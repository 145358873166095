.grid-container--home {
  gap: 1rem;
}

.grid-container--home span {
  margin-block: 1rem;
}

body:has(.grid-container--home) {
  background-image: url(../../public/assets/home/background-home-mobile.jpg);
}

@media screen and (min-width: 35em) {
  body:has(.grid-container--home) {
    background-image: url(../../public/assets/home/background-home-tablet.jpg);
  }
}

@media screen and (min-width: 65em) {
  body:has(.grid-container--home) {
    background-image: url(../../public/assets/home/background-home-desktop.jpg);
  }

  .grid-container--home {
    padding-bottom: max(6rem, 20vh);
    align-items: end;

    overflow-x: hidden;
  }

  .grid-container--home>*:first-child {
    grid-column: 2;
  }

  .grid-container--home>*:last-child {
    grid-column: 3;
  }
}