body:has(.grid-container--technology) {
  background-image: url(../../public/assets/technology/background-technology-mobile.jpg);
}

.grid-container--technology {
  display: grid;
  grid-template-rows: max-content min(40vw) 7rem min(25vh);
  grid-template-areas:
    'title'
    'image'
    'tabs'
    'content';
}

.numbered-title {
  grid-area: title;
}

.technology-info {
  grid-area: content;
  align-self: start;
}

.grid-container--technology>picture {
  grid-area: image;
  grid-column: span 2;
  justify-self: center;
}


.grid-container--technology>:global(.number-indicators) {
  grid-area: tabs;
}



@media screen and (min-width: 35em) {
  body:has(.grid-container--technology) {
    background-image: url(../../public/assets/technology/background-technology-tablet.jpg);
  }
}

@media screen and (min-width: 65em) {
  body:has(.grid-container--technology) {
    background-image: url(../../public/assets/technology/background-technology-desktop.jpg);
  }

  .grid-container--technology {
    grid-template-columns: minmax(1rem, 1fr) minmax(0, 5rem) minmax(0, 30rem) minmax(0, 30rem) minmax(1rem, 1fr);
    grid-template-rows: auto minmax(34rem, auto);
    grid-template-areas:
      '. title title . .'
      '. tabs content image .';
    justify-items: start;
    align-items: center;
    padding-right: 0;
  }

  .grid-container--technology>picture {
    justify-self: end;
  }

  .grid-container--technology>:global(.number-indicators) {
    flex-direction: column;
  }

  .technology-info {
    align-self: center;
  }
}