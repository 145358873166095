body:has(.grid-container--crew) {
  background-image: url(../../public/assets/crew/background-crew-mobile.jpg);
}

.grid-container--crew {
  grid-template-rows: max-content min(75vw) 5rem min(15vh);
  grid-template-areas:
    'title'
    'image'
    'dots'
    'content';
}

.grid-container--crew>picture {
  grid-area: image;
  max-width: 60%;
  border-bottom: 1px solid hsl(var(--clr-white) / 0.1);
  align-self: end;
}

.grid-container--crew>:global(.dot-indicators) {
  grid-area: dots;
}

.grid-container--crew>:global(.crew-info) {
  grid-area: content;
  align-self: start;
}

.grid-container--crew>.crew-info h2 {
  color: hsl(var(--clr-white) / 0.5);
}

@media screen and (min-width: 35em) {
  .grid-container--crew {
    padding-bottom: 0;
    grid-template-areas:
      'title'
      'content'
      'dots'
      'image';
  }
}

@media screen and (min-width: 35rem) {
  .grid-container--crew {
    grid-template-rows: max-content min(20vh) min(5vh) min(100vw);
  }

  body:has(.grid-container--crew) {
    background-image: url(../../public/assets/crew/background-crew-tablet.jpg);
  }

  .grid-container--crew>picture {
    max-width: 100%;
  }
}

@media screen and (min-width: 65em) {
  body:has(.grid-container--crew) {
    background-image: url(../../public/assets/crew/background-crew-desktop.jpg);
  }

  .grid-container--crew {
    grid-template-rows: unset;
    grid-template-columns: minmax(1rem, 1fr) minmax(0, 40rem) minmax(0, 20rem) minmax(1rem, 1fr);
    justify-items: start;
    grid-template-areas:
      '. title image .'
      '. content image .'
      '. dots image .';
  }

  .grid-container--crew>.dot-indicators {
    justify-self: start;
  }

  .grid-container--crew>picture {
    grid-column: span 2;
    max-width: 90%;
  }



  .grid-container--crew>:global(.crew-info) {
    align-self: end;
    min-height: 16rem;
  }
}